<template>
  <b-sidebar
    v-model="show"
    id="new-expense-category"
    title="Nueva categoría de gasto"
    right
    shadow
  >
    <ValidationObserver ref="formValidation">
      <b-form @submit.prevent>
        <div class="row">
          <div class="col-md-12 p-3">
            <div class="form-group">
              <label>Nombre Categoría</label>
              <ValidationProvider
                name="Nombre Categoría"
                rules="required"
                #default="{ errors }"
              >
                <b-form-input
                  v-model="expense_category.name"
                  name="name"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="text-center">
          <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button button="submit" @click="store()" variant="primary">
              Guardar
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </ValidationObserver>
  </b-sidebar>
</template>

<script>
import utilitiesService from "@/services/utilities.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { BButton, BSidebar, BFormInput, BForm, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  props: {
    vis: {
      type: Boolean,
      default: false
    }
  },
  name: "new-expense-category-modal",
  data() {
    return {
      show: false,
      expense_category: {
        name: ""
      },
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("closeModal", false);
      this.resetDataForm();
    },
    resetDataForm() {
      Object.assign(
        this.$data.expense_category,
        this.$options.data().expense_category
      );
    },
    store() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          this.loading = true;
          let expense_category = this.expense_category;
          utilitiesService
            .storeExpenseCategory(expense_category)
            .then(res => {
              if (res.data.success) {
                this.$emit("storeExpenseCategory", res.data.item);
              } else {
              }
              this.loading = false;
              this.resetDataForm();
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    }
  },
  watch: {
    show(newVal) {
      if (!newVal) {
        this.close();
      }
    },
    vis(newVal) {
      this.show = newVal;
    }
  }
};
</script>

<style scoped>
.mt-30 {
  margin-top: 30px;
}
</style>
