import api from "@/api";

class DocumentReceptionService {
  static getDocumentsReception(page = 1, search) {
    let params = "";

    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.perPage) {
      params += `&perPage=${search.perPage}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }

    return api.get(`get-documents-reception?page=${page}${params}`);
  }

  static getReceptionDetail(id) {
    return api.get(`reception/${id}`);
  }

  static storeDocumentReception(reception = {}) {
    return api({
      method: "post",
      url: "store-document-reception",
      data: reception
    });
  }

  //Actualizacion de Aceptacion de documentos
  static updateDocumentReception(reception = {}) {
    return api({
      method: "post",
      url: "update-document-reception",
      data: reception
    });
  }
}

export default DocumentReceptionService;
