<template>
  <div>
    <!-- Table Container Card -->
    <b-overlay :show="loading" rounded="sm">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="9"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div>
                <label for="">Por página</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector mr-1"
                />
              </div>

              <div>
                <b-button
                  variant="success"
                  class="btn-icon mr-1 mt-2"
                  @click="get"
                >
                  <feather-icon icon="RefreshCwIcon" size="16" />
                </b-button>
              </div>

              <b-button
                v-if="
                  (user_state && user_state.role == 'admin') ||
                    permissions.includes('create_reception')
                "
                class="mt-1"
                variant="primary"
                @click="newReception()"
              >
                <feather-icon icon="PlusIcon" size="16" />
                Nueva recepción
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="search"
                  @keyup.enter="get"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refNoteListTable"
          :items="notes"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No se encontraron registros"
          class="position-relative"
        >
          <template #cell(reference)="data">
            {{ data.item.reference }}
          </template>

          <template #cell(data)="data">
            {{ JSON.parse(data.item.data).Emisor.Nombre }}
          </template>

          <template #cell(message)="data">
            {{ getMessageReception(data.item.message) }}
          </template>

          <template #cell(hacienda)="data">
            <status-hacienda :status_hacienda="data.item.hacienda" />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap text-left ml-2">
              <!-- <b-overlay
                :show="note_action_execute == data.item.id"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-tooltip
                  :target="`tooltip-pdf-${data.item.id}`"
                  placement="left"
                >
                  <span>Generar pdf</span>
                </b-tooltip>
                <feather-icon
                  v-if="
                    (user_state && user_state.role == 'admin') ||
                      permissions.includes('generate_pdf_reception')
                  "
                  :id="`tooltip-pdf-${data.item.id}`"
                  icon="FileTextIcon"
                  class="cursor-pointer mr-1"
                  size="18"
                  @click="generatePdf(data.item.id, data.item.reference)"
                />
              </b-overlay> -->

              <!-- <b-tooltip
                :target="`tooltip-mail-${data.item.id}`"
                placement="right"
              >
                <span>Enviar por correo</span>
              </b-tooltip>
              <feather-icon
                v-if="
                  (user_state && user_state.role == 'admin') ||
                    permissions.includes('send_email_reception')
                "
                :id="`tooltip-mail-${data.item.id}`"
                icon="MailIcon"
                class="cursor-pointer mr-1"
                size="18"
              /> -->

              <b-dropdown variant="link" toggle-class="p-0" no-caret right>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="text-body"
                  />
                </template>

                <b-dropdown-item
                  v-if="
                    (user_state && user_state.role == 'admin') ||
                      permissions.includes('download_xmls_reception')
                  "
                  @click="
                    downloadXml(
                      data.item.key50digits,
                      data.item.message_type,
                      'upload'
                    )
                  "
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">XML Subido</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    (user_state && user_state.role == 'admin') ||
                      permissions.includes('download_xmls_reception')
                  "
                  @click="
                    downloadXml(
                      data.item.consecutive,
                      data.item.message_type,
                      'send'
                    )
                  "
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">XML Enviado</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    (user_state && user_state.role == 'admin') ||
                      permissions.includes('download_xmls_reception')
                  "
                  @click="
                    downloadXml(
                      data.item.consecutive,
                      data.item.message_type,
                      'response'
                    )
                  "
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">XML Respuesta</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted" v-if="totalRegisters > 0"
                >Mostrando {{ fromRegister }} a {{ toRegister }} de
                {{ totalRegisters }} registros
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-if="notes.length !== totalRegisters"
                v-model="currentPage"
                :total-rows="totalRegisters"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>

    <!-- b sidebar -->
    <b-sidebar
      v-model="showNewReceptionModal"
      id="sidebar-right"
      title="Aceptar Documento"
      right
      shadow
    >
      <div
        class="col-md-12"
        v-loading="loading"
        element-loading-text="Cargando datos..."
      >
        <ValidationObserver ref="formValidation">
          <form @submit.prevent method="POST">
            <div class="form-group">
              <validation-provider
                #default="{ errors }"
                name="XML"
                rules="required|mimes:xml"
              >
                <label>Subir XML del documento</label>
                <b-form-file
                  ref="upload"
                  placeholder="Elige un xml"
                  browse-text="Examinar"
                  accept=".xml"
                  v-model="reception.xml_file"
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <b-form-group label="Condición Aceptación" label-for="currency">
              <validation-provider
                #default="{ errors }"
                name="Condición Aceptación"
                rules="required"
              >
                <v-select
                  id="message"
                  v-model="reception.message"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="conditions"
                  :clearable="false"
                  class="per-page-selector"
                  label="name"
                  name="Moneda"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div
              class="form-group"
              v-if="reception.message.code && reception.message.code != 1"
            >
              <label>Detalle mensaje recepción</label>
              <validation-provider
                #default="{ errors }"
                name="Detalle mensaje"
                rules="required"
              >
                <b-form-input
                  v-model="reception.message_detail"
                  v-validate="'required'"
                  name="message_detail"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <b-form-group label="Condición impuesto" label-for="currency">
              <validation-provider
                #default="{ errors }"
                name="Condición impuesto"
                rules="required"
              >
                <v-select
                  id="message"
                  v-model="reception.tax_condition"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="tax_conditions"
                  :clearable="false"
                  class="per-page-selector"
                  label="name"
                  name="Condición impuesto"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div
              class="form-group"
              v-if="
                reception.message.code != 3 && reception.tax_condition.code != 5
              "
            >
              <validation-provider
                #default="{ errors }"
                name="Monto total impuesto acreditar"
                rules="required"
              >
                <label>
                  Monto total impuesto acreditar
                  <i
                    id="tax-credit-help"
                    class="fa fa-question-circle text-primary"
                  ></i>
                  <b-tooltip target="tax-credit-help" triggers="hover">
                    Se puede utilizar, en los casos que el monto total del
                    impuesto pagado, <br />
                    no forme en su totalidad parte del crédito aplicable.
                  </b-tooltip>
                </label>
                <b-form-input
                  v-model="reception.tax_credit"
                  v-validate="'decimal'"
                  name="tax_credit"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div
              class="form-group"
              v-if="
                reception.message.code != 3 && reception.tax_condition.code != 5
              "
            >
              <validation-provider
                #default="{ errors }"
                name="Monto total de gasto aplicable"
                rules="required"
              >
                <label>
                  Monto total de gasto aplicable

                  <i
                    id="aplicable-expense-help"
                    class="fa fa-question-circle text-primary"
                  ></i>
                  <b-tooltip target="aplicable-expense-help" triggers="hover">
                    Se puede utilizar, en los casos que el monto total del
                    comprobante, <br />
                    no forme en su totalidad un gasto o parte del IVA pagado se
                    convierten en gastos.
                  </b-tooltip>
                </label>
                <b-form-input
                  v-model="reception.applicable_expense"
                  v-validate="'decimal'"
                  name="applicable_expense"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <b-form-group label-for="currency">
              <label for=""
                >Categoría Gasto
                <a href="#" @click.prevent="newExpenseCategory()">
                  <i class="fa fa-plus"></i>
                  Nueva
                </a></label
              >

              <validation-provider
                #default="{ errors }"
                name="Categoría Gasto"
                rules="required"
              >
                <v-select
                  id="message"
                  v-model="reception.expense_category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categories"
                  :clearable="false"
                  class="per-page-selector"
                  label="name"
                  name="Categoría Gasto"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="text-center" style="padding-top:20px">
              <b-button
                variant="primary"
                button="submit"
                @click="storeDocument()"
              >
                <i class="fa fa-send"></i>
                Enviar
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-sidebar>
    <!-- end b sidebar -->

    <new-expense-category-modal
      @storeExpenseCategory="storeExpenseCategory"
      @closeModal="closeModal()"
      :vis="show_new_expense_category_modal"
    ></new-expense-category-modal>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  BFormFile,
  BFormGroup,
  BSidebar
} from "bootstrap-vue";
import Swal from "sweetalert2";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { downloadBlob } from "@/helpers";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DocumentReceptionService from "@/services/reception.service";
import utilitiesService from "@/services/utilities.service";
import moment from "moment";
moment.locale("es");
import { ValidationProvider, ValidationObserver } from "vee-validate";
import NewExpenseCategoryModal from "@/components/NewExpenseCategoryModal.vue";
import { mapState } from "vuex";
export default {
  directives: {
    Ripple
  },
  components: {
    BTooltip,
    BDropdown,
    BDropdownItem,
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    BModal,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BSidebar,
    NewExpenseCategoryModal
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  data() {
    return {
      note_action_execute: "",
      loading: false,
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50, 100],
      search: "",
      currentPage: 1,
      lastPage: 1,
      totalRegisters: 1,
      toRegister: 10,
      fromRegister: 1,
      flagEdit: false,
      storeEditNote: false,
      reception: {
        message: "",
        message_detail: "",
        xml_file: null,
        expense_category: "",
        tax_condition: "",
        tax_credit: "",
        applicable_expense: "",
        id: "",
        type_update_doc: "single"
      },
      message: {},
      conditions: [
        { id: 1, code: 1, name: "Aceptado" },
        { id: 2, code: 2, name: "Aceptado Parcialmente" },
        { id: 3, code: 3, name: "Rechazado" }
      ],
      fileList: [],
      categories: [],
      show_new_expense_category_modal: false,
      showNewReceptionModal: false,
      //editMessageReceptorModal: false,
      messageName: "",
      tax_conditions: [],
      accept_multiples: [],

      notes: [],
      tableColumns: [
        { key: "reference", label: "Número Documento" },
        { key: "data", label: "Emisor", sortable: true },
        { key: "emision_date", label: "Fecha emisión", sortable: true },
        { key: "total", label: "Monto", sortable: true },
        { key: "document_type", label: "Tipo", sortable: true },
        { key: "message", label: "Aceptación", sortable: true },
        { key: "hacienda", label: "Hacienda", sortable: true },
        { key: "actions", label: "Acciones", tdClass: "p-0 pr-1" }
      ]
    };
  },
  watch: {
    storeEditService(newval) {
      if (!newval) {
        this.flagEdit = false;
        this.service = { ...this.$options.data().service };
      }
    },
    currentPage(newval) {
      this.get(newval);
    },
    perPage() {
      this.get(this.currentPage);
    }
  },
  methods: {
    getMessageReception(type) {
      let message = "Aceptado";
      if (type === 2) {
        message = "Parcialmente aceptado";
      } else if (type === 3) {
        message = "Rechazado";
      }

      return message;
    },
    newReception() {
      this.showNewReceptionModal = true;
    },
    newExpenseCategory() {
      this.show_new_expense_category_modal = true;
    },
    storeExpenseCategory(new_category) {
      this.categories.push(new_category);
      this.reception.expense_category = new_category;
      this.closeModal();
    },
    closeModal() {
      this.show_new_expense_category_modal = false;
    },
    getCategories() {
      utilitiesService
        .getExpenseCategories()
        .then(res => {
          this.categories = res.data;
        })
        .catch(() => {
          alert("error al obtener expense categories");
        });
    },
    storeDocument() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          this.loading = true;
          let formData = new FormData();
          Object.keys(this.reception).forEach(key => {
            let value;
            if (
              typeof this.reception[key] === "object" &&
              !(this.reception[key] instanceof File)
            ) {
              value = JSON.stringify(this.reception[key]);
            } else {
              value = this.reception[key];
            }
            formData.append(key, value);
          });

          DocumentReceptionService.storeDocumentReception(formData)
            .then(res => {
              console.log(res);
              if (res.data.success) {
                this.toast(res.data.message);
                this.get(1, this.searchData);
                this.showNewReceptionModal = false;
                this.reception = {
                  message: "",
                  message_detail: "",
                  xml_file: null,
                  expense_category: "",
                  tax_condition: "",
                  tax_credit: "",
                  applicable_expense: "",
                  id: "",
                  type_update_doc: "single"
                };
              } else {
                this.swal(res.data.message);
              }
              this.loading = false;
            })
            .catch(error => {
              this.loading = false;
              this.swal(error.message);
            });
        }
      });
    },
    updateDocument() {
      //JRA Actualizacion de la aceptacion de documentos
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true;

          this.reception.accept_multiples = this.accept_multiples;

          let formData = new FormData();
          Object.keys(this.reception).forEach(key => {
            let value;
            if (
              typeof this.reception[key] === "object" &&
              !(this.reception[key] instanceof File)
            ) {
              value = JSON.stringify(this.reception[key]);
            } else {
              value = this.reception[key];
            }
            formData.append(key, value);
          });

          DocumentReceptionService.updateDocumentReception(formData)
            .then(res => {
              if (res.data.success) {
                this.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "success",
                  duration: 1500
                });
                this.accept_multiples = [];
                delete this.reception.accept_multiples;
                this.getMessages(1, this.searchData);
                this.editMessageReceptorModal = false;
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "warning",
                  duration: 5000
                });
              }
              this.loading = false;
            })
            .catch(error => {
              this.$message({
                showClose: true,
                message: error.message,
                type: "error"
              });
              this.loading = false;
            });
        }
      });
    },
    async getTaxConditions() {
      try {
        let { data } = await utilitiesService.getTaxConditions();
        this.tax_conditions = data;
      } catch (error) {
        alert(error.message);
      }
    },
    async generatePdf(note_id, reference) {
      try {
        this.note_action_execute = note_id;

        const { data: res } = await NoteService.generatePdf(note_id);

        this.downloadFile(res, `Factura-${reference}.pdf`);
        this.note_action_execute = "";
      } catch (e) {
        this.note_action_execute = "";
      }
    },
    downloadFile(file, namefile) {
      return downloadBlob(file, namefile);
    },
    downloadXml(key50digits, document_type, folder) {
      utilitiesService
        .downloadXml(key50digits, document_type, folder)
        .then(response => {
          downloadBlob(response.data, `${key50digits}.xml`);
        })
        .catch(error => {
          alert("error");
        });
    },
    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },
    async get(page = 1, searchQuery = {}) {
      try {
        this.loading = true;
        searchQuery.perPage = this.perPage;
        searchQuery.term = this.search;
        const {
          data: res
        } = await DocumentReceptionService.getDocumentsReception(
          page,
          searchQuery
        );
        console.log("MANA", res);
        this.notes = res.data;
        this.loading = false;
        //Pagination
        this.lastPage = res.last_page;
        this.toRegister = res.to;
        this.fromRegister = res.from;

        this.totalRegisters = res.total;
      } catch (error) {
        this.loading = false;
      }
    },
    toast(title, variant = "success", icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant
        }
      });
    },
    swal(title, content = "") {
      Swal.fire({
        title: title,
        text: content,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      });
    }
  },

  created() {
    this.get();
    this.getTaxConditions();
    this.getCategories();
  }
};
</script>
<style scoped></style>
